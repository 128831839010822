<template>
  <!-- 解决方案主页 -->
  <div class="solution">
    <div class="banner">
      <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[0].phoneImg" v-else />
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-con">
          <p class="p1">{{ elmentList[0].descList[0].descName }}</p>
          <p class="p2">
            {{ elmentList[0].descList[1].descName }}
          </p>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="solution-content">
      <!-- 行业领域 -->
      <p class="content-title">INDUSTRY SECTOR</p>
      <div class="mySwiper img-swiper">
        <div class="swiper-wrapper screen-swiper">
          <div
            class="swiper-slide"
            v-for="(swiper, indexSwiper) in solutionList.length"
            :key="indexSwiper"
          >
            <!-- 滑动切换内容 -->
            <div class="slide-item">
              <div
                v-for="(item, index) in solutionList.length === 1
                  ? solutionList[0]
                  : solutionList[indexSwiper]"
                :key="index"
                class="content-area-item"
                :data-solutionId="item.solutionId"
              >
                <div class="area-img" :data-solutionId="item.solutionId">
                  <img
                    :data-solutionId="item.solutionId"
                    :src="item.solutionPcImg ? item.solutionPcImg : ''"
                    v-if="$store.state.pageset.pcshow"
                  />
                  <img :src="item.solutionPhoneImg ? item.solutionPhoneImg : ''" v-else />
                </div>
                <div class="content-area-dec" v-animate="'queue-bottom'">
                  <p class="p1" :data-solutionId="item.solutionId">
                    {{ item.solutionName }}
                  </p>
                  <p class="p2" :data-solutionId="item.solutionId">
                    Learn more<i class="el-icon-arrow-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSolution } from "@/api/dataSet/solution.js";
import Swiper from "swiper";
export default {
  data() {
    return {
      elmentList: [
        {
          pcImg: require("../images/pc/banner1.jpg"),
          phoneImg: require("../images/mobile/banner1.jpg"),
          descList: [
            {
              descName: "SEVNCE provides standardized product services and solutions in more than 40 countries and regions globally.",
              sort: 1,
            },
            {
              descName:
                "The application of AI equipped SEVNCE robotics standardizes product services while simultaneously investigating problematic issues, recognizing production pain points providing critical time sensitive, customized solutions.",
              sort: 2,
            },
          ],
          type: "img",
          sort: 1,
        },
      ],
      aboutswiper: null,
      solutionList: [],
    };
  },
  created() {
    this.$store.dispatch("pageset/getbannerIndex", 0);
    document.documentElement.style.setProperty("--var-primary-color", "#fff");
    this.getSolution();
  },
  // 销毁滚轮事件
  destroyed() {
    // 销毁swiper
    this.aboutswiper.destroy();
  },
  methods: {
    // 初始化轮播图
    aboutSwiper() {
      this.aboutswiper = new Swiper(".mySwiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: true,
        speed: 2000,
        loop: true,
        effect: "fade", //切换效果
        fadeEffect: {
          slideShadows: true,
          limitRotation: true,
          crossFade: true, //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
        },
        on: {
          click: (e) => {
            // 在loop模式下，超出实际数量的轮播项实际上是被复制出来的，
            //是swiper虚拟slide进行填充，对这些虚拟slide元素进行操作无效。
            // 解决办法
            this.handleClickCard(e);
          },
        },
      });
    },

    // 获取解决方案
    async getSolution() {
      let res = await getSolution(this.$store.getters.change==='zh'?0:1);
      if (res.code === 200) {
        // 组装一个特殊的‘固定式云台’解决方案
        res.data.push({
          solutionId: 999,
          solutionName: "SAAS system service",
          solutionDescription: "",
          solutionPcImg: require("../solutionArea/PTZsolution/images/pc/PTZ.jpg"),
          solutionPhoneImg: require("../solutionArea/PTZsolution/images/mobile/PTZ.jpg"),
        });
        // 将解决方案处理成4个一列
        if (res.data.length) {
          var chunk = 4;
          for (var i = 0, j = res.data.length; i < j; i += chunk) {
            this.solutionList.push(res.data.slice(i, i + chunk));
          }
        }
        setTimeout(() => {
          this.aboutSwiper();
        }, 80);
      }
    },

    handleClickCard(e) {
      let solutionId = e.target.getAttribute("data-solutionId");
      if (solutionId) {
        if (solutionId === "999") {
          //固定式云台单独页面
          this.$router.push("/en/ptzSolution");
        } else {
          this.$router.push({
            path: "/en/solutionArea",
            query: {
              solutionId: solutionId,
            },
          });
        }
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/common.scss";

/deep/.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background: #5c5e62 !important;
  opacity: 1 !important;
  margin-right: 5px !important;
}
/deep/.swiper-pagination {
  position: relative !important;
}
/deep/.swiper-pagination-bullet-active {
  background: rgb(218, 217, 217) !important;
}
.solution {
  width: 100%;
  height: 100%;
  font-family: "SourceHanSansSC" !important;
}

.banner {
  height: 100vh !important;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .dec {
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 320px;
    color: white;
    .dec-con {
      width: 1285px;
    }
    .p1 {
      font-size: 40px;
    }
    .p2 {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}

.solution-content {
  padding: 0px 290px ;
  .content-title {
    font-size: 40px;
    text-align: center;
    margin: 80px 0px 50px 15px;
  }

  // 解决方案轮播
  .img-swiper {
    width: 100%;
    display: block;
    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: 100%;
        .slide-item {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          flex-shrink: 1;
          .content-area-item {
            width: 50%;
            padding: 0px 15px 28px 15px;
            box-sizing: border-box;
            position: relative;
            .area-img {
              height: 400px;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              position: relative;
            }
            .content-area-dec {
              position: absolute;
              bottom: 60px;
              left: 40px;
              color: white;
              .p1 {
                font-size: 30px;
              }
              .p2 {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
    .solution-content{
        padding: 0 200px !important;
    }
}
@media screen and (max-width: 750px) {
  .banner {
    height: 100vh !important;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
    }

    .dec {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      top: 95px;
      color: white;
      .dec-con {
        width: 300px !important;
      }
      .p1 {
        font-size: 19px !important;
      }
      .p2 {
        font-size: 10px !important;
        margin-top: 10px !important;
      }
    }
  }

  .solution-content {
    padding: 0px 13px !important;

    .content-title {
      font-size: 21px !important;
      margin: 20px 0px 20px 12px !important;
    }
  }
}
</style>
